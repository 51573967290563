import { navigate } from "@reach/router";
import React, { useEffect } from "react";

import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/mdbreact/scss/mdb-free.scss";

export default function NotFoundPage() {
	useEffect(() => {
		navigate("/");
	}, []);
	return <></>;
}
